import {useLanguageStore} from "@/shared/stores";

export function getLocalDateFromServer(dateString) {
	var compactDate = getCompatibleDate(dateString);
	return new Date(compactDate - compactDate.getTimezoneOffset() * 60 * 1000);
}
export function localToServerTime(date) {
	let compatibleDate = getCompatibleDate(date);
	return new Date(compatibleDate.getTime() + compatibleDate.getTimezoneOffset() * 60 * 1000);
}
export function getCompatibleDate(date) {

	let testDate = new Date(date);
	if (Number.isNaN(testDate.getMonth())) {
		let ar = date.split(/[- :.]/);
		let ms = (ar[6] ? Number(ar[6]) : 0) / 1000;
		testDate = new Date(ar[0], ar[1] - 1, ar[2], ar[3], ar[4], ar[5], ms);
	}

	return testDate;

}
export function getCompatibleDateOnly(date) {

	let testDate = new Date(date);
	if (Number.isNaN(testDate.getMonth())) {
		let ar = date.split(/[- :.]/);
		testDate = new Date(ar[0], ar[1] - 1, ar[2]);
	}

	return testDate;

}

export function isTodayServerDate(date, convertToGMT = true) {
	let compatibleDate = convertToGMT ? getLocalDateFromServer(date) : date;
	const today = new Date();
	return today.getFullYear() === compatibleDate.getFullYear() &&
		today.getMonth() === compatibleDate.getMonth() &&
		today.getDate() === compatibleDate.getDate();
}

export function lessThan24HoursAgo(date) {
	let milliSecondsDay = 24 * 60 * 60 * 1000;
	let milliSecondsNow = new Date().getTime();
	return getCompatibleDate(date).getTime() > milliSecondsNow - milliSecondsDay;
}

export function isTomorrowServerDate(date) {
	let compatibleDate = getLocalDateFromServer(date);
	const tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate() + 1);
	return tomorrow.getFullYear() === compatibleDate.getFullYear() &&
		tomorrow.getMonth() === compatibleDate.getMonth() &&
		tomorrow.getDate() === compatibleDate.getDate();
}

export function formatDateOnly(d) {
	if(!d) {
		return "";
	}
	let month = doubleFigure(d.getMonth() + 1),
		day = doubleFigure(d.getDate()),
		year = d.getFullYear();
	return [year, month, day].join('-');
}

export function formatDateTime(date, convertToGMT = true) {
	let d = convertToGMT ? localToServerTime(date) : getCompatibleDate(date),
		minutes = doubleFigure(d.getMinutes()),
		hours = doubleFigure(d.getHours()),
		month = doubleFigure(d.getMonth() + 1),
		day = doubleFigure(d.getDate()),
		year = d.getFullYear();
	return [year, month, day].join('-') + " " + [hours, minutes, '00'].join(':');
}

export function formatDateTimeWithSeconds(date, convertToGMT = true) {
	let d = convertToGMT ? localToServerTime(date) : getCompatibleDate(date),
		seconds = doubleFigure(d.getSeconds()),
		minutes = doubleFigure(d.getMinutes()),
		hours = doubleFigure(d.getHours()),
		month = doubleFigure(d.getMonth() + 1),
		day = doubleFigure(d.getDate()),
		year = d.getFullYear();
	return [year, month, day].join('-') + " " + [hours, minutes, seconds].join(':');
}
export function doubleFigure(number) {
	return parseInt(number) < 10 ? "0"+number : number;
}


//formats

export function formatDateMMDDHHmm(d) {
	let minutes = doubleFigure(d.getMinutes()),
		hours = doubleFigure(d.getHours()),
		month = doubleFigure(d.getMonth() + 1),
		day = doubleFigure(d.getDate());
	return [day, month].join('/') + " " + [hours, minutes].join(':');
}
export function formatDateMMDD(d) {
	let month = doubleFigure(d.getMonth() + 1),
		day = doubleFigure(d.getDate());
	return [day, month].join('/');
}
export function formatDateYYMMDD(d) {
	let year = d.getFullYear(),
		month = doubleFigure(d.getMonth() + 1),
		day = doubleFigure(d.getDate());
	return [day, month, year].join('/');
}
export function formatTimeHHmm(d) {
	let minutes = doubleFigure(d.getMinutes()),
		hours = doubleFigure(d.getHours());
	return [hours, minutes].join(':');
}
export function getMinutesPassedFromServerDate(dateString) {
	return Math.floor(((new Date()).getTime() - getLocalDateFromServer(dateString).getTime()) / (1000 * 60));
}
export function formatDateDayWeekFull(d) {
	let languageStore = useLanguageStore();
	const daysOfWeek = [
		languageStore.getString('week_day_sun'),
		languageStore.getString('week_day_mon'),
		languageStore.getString('week_day_tue'),
		languageStore.getString('week_day_wed'),
		languageStore.getString('week_day_thu'),
		languageStore.getString('week_day_fri'),
		languageStore.getString('week_day_sat')
	];
	const year = d.getFullYear().toString().substring(2);
	const month = doubleFigure(d.getMonth() + 1);
	const day = doubleFigure(d.getDate());
	const hours = doubleFigure(d.getHours());
	const minutes = doubleFigure(d.getMinutes());
	const dayOfWeek = daysOfWeek[d.getDay()];
	return `${dayOfWeek} ${month}/${day}/${year} ${hours}:${minutes}`;
}

export function getMinutesPassedFromDate(date, ago = false) {

	let languageStore = useLanguageStore();

	let timeString =  Math.floor(((new Date()).getTime() - date.getTime()) / (1000 * 60));
	if (timeString >= -3 && timeString <= 0) {
		timeString = languageStore.getString('caps_now');
	} else if (timeString > 0 && timeString < 120) {
		let translateKey = ago ? "x_min_ago" : "x_min";
		timeString = languageStore.getString(translateKey, [timeString]);
	} else if (timeString > 0 && timeString < 60 * 24 * 3) {
		let translateKey = ago ? "x_hours_ago" : "x_hours";
		timeString = languageStore.getString(translateKey, [Math.floor(timeString / 60)]);
	} else if (timeString > 0) {
		let translateKey = ago ? "x_days_ago" : "x_days";
		timeString = languageStore.getString(translateKey, [Math.floor(timeString / (60 * 24))]);
	} else {
		console.log("getTimeString: timeString is negative -> " + timeString);
		timeString = "";
	}

	return timeString;
}
