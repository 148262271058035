import { defineStore } from 'pinia';
import {useLanguageStore} from "@/shared/stores";

export const useNotificationStore = defineStore('notifications', {
	state: () => ({
		notifications: [],
	}),
	actions: {
		showNotification(id, bodyParams = null) {
			let notification = this.notifications.find(n => n.id === id);
			if(notification) {
				notification.open(bodyParams);
			}
		}
	},
});
