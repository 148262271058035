import { defineStore } from 'pinia';
import langObject from "@/shared/assets/json/langs.json";
import langObjectAuto from "@/shared/assets/json/auto.json";
import API from "@/shared/helpers/API";

let mergeLangs = (langObject, langObjectAuto) => {
	let result = {};
	for(let lang in langObject) {
		result[lang] = langObject[lang];
	}
	for(let lang in langObjectAuto) {
		if(!result.hasOwnProperty(lang)) {
			result[lang] = langObjectAuto[lang];
		}
	}
	return result;
}

export const useLanguageStore = defineStore('language', {
	state: () => ({
		strings: mergeLangs(langObject, langObjectAuto),
		availableLanguages: ['it', 'en'],
		preAutoTranslatedLanguages: ['es', 'fr', 'de'],
		lang: null,
		automaticTranslation: true,
		translating: false,
		files: ["shared"],
	}),
	getters: {
		getString: state => { return (key, params = null) => {
				if(!state.lang) {
					state.setLang();
				}
				let result = null;
				if(state.strings.hasOwnProperty(state.lang)) {
					if(state.strings[state.lang].hasOwnProperty(key)) {
						result = state.strings[state.lang][key];
					}
				}
				if(!result) {
					let defaultLang = typeof DEFAULT_LANGUAGE !== "undefined" ? DEFAULT_LANGUAGE : "en";
					if(state.automaticTranslation && state.strings.hasOwnProperty(defaultLang) && state.strings[defaultLang].hasOwnProperty(key)) {
						result = state.strings[defaultLang][key];
					} else if(state.automaticTranslation && state.strings.hasOwnProperty("en") && state.strings["en"].hasOwnProperty(key)) {
						result = state.strings["en"][key];
					} else {
						result = key;
					}
				}
				if (params) {
					for (let i = 0; i < params.length; i++) {
						result = result.replaceAll("[" + i + "]", params[i]);
					}
				}
				return result;
			}
		},
		readableEurValue: state => { return (value, digits = 2) => {
				if(!state.lang) {
					state.setLang();
				}
				return Number(value || 0).toLocaleString([state.lang || "en"], {style: 'currency', currency: 'EUR', maximumFractionDigits: digits, minimumFractionDigits: digits});
			}
		},
	},
	actions: {
		setLang(overrideLang = null, callback = null) {
			let selectedLang = null;
			let autoTranslate = false;
			if (overrideLang && overrideLang.length === 2) {
				if(this.availableLanguages.includes(overrideLang)) {
					selectedLang = overrideLang;
				} else if(this.automaticTranslation) {
					autoTranslate = overrideLang;
				}
			}
			if(selectedLang === null) {
				let defaultLang = typeof DEFAULT_LANGUAGE !== "undefined" ? DEFAULT_LANGUAGE : "en";

				let lang = localStorage.getItem('chosen-language') || navigator.language || navigator.userLanguage;
				lang = lang ? lang.substring(0, 2) : defaultLang;
				if(this.availableLanguages.includes(lang)) {
					selectedLang = lang;
				} else if(this.automaticTranslation) {
					autoTranslate = lang;
				} else {
					selectedLang = this.availableLanguages[0];
				}
			}
			if(autoTranslate) {
				this.autoTranslate(autoTranslate, callback);
			} else {
				this.lang = selectedLang;
				localStorage.setItem('chosen-language', this.lang);
				callback && callback();
			}
		},
		autoTranslate(lang, callback = null) {
			if(this.translating) {
				callback && callback();
				return;
			}
			this.translating = true;
			let keysToSkip = null;
			if(this.preAutoTranslatedLanguages.includes(lang)) {
				keysToSkip = [];
				for(let key in this.strings[lang]) {
					keysToSkip.push(key);
				}
				keysToSkip = JSON.stringify(keysToSkip);
			}
			API.init().translateInterface(lang, JSON.stringify(this.files), keysToSkip, (result) => {
				this.translating = false;
				if(result && result.result === "OK" && result.data && result.data.hasOwnProperty(lang)) {
					this.addStrings(result.data, null);
					this.availableLanguages.push(lang);
					this.setLang(lang, callback);
				} else {
					this.setLang(this.availableLanguages[0], callback);
				}
			});
		},
		addStrings(strings, fileIdentifier, stringsAuto = null) {
			if(fileIdentifier) {
				this.files.push(fileIdentifier);
			}
			for(let lang in strings) {
				this.strings[lang] = {...this.strings[lang], ...strings[lang]};
			}
			if(stringsAuto) {
				for(let lang in stringsAuto) {
					this.strings[lang] = {...this.strings[lang], ...stringsAuto[lang]};
				}
			}
		},

	},
});
