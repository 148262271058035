export function getRandomAlphanumericString(length = 8) {
	let text = '';
	let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	for (var i = 0; i < length; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length));
	}
	return text;
}

export function getPriceFormatted(price, currency = null) {
	let priceValue = Number(price)/100.0;
	let priceFormatted = priceValue.toFixed(2);
	if (currency) {
		priceFormatted = priceFormatted+" "+currency;
	}
	return priceFormatted;
}
export function validateEmail(email) {
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}
export function validateLink(link) {
	const re = /^(http|https):\/\/[^ "]+$/;
	return re.test(String(link).toLowerCase());
}
export function validatePhone(tel) {
	const re = /^[0-9\s+-.()]{5,14}$/im;
	return re.test(tel);
}

export function replaceNonAlphanumeric(text) {
	const re = /[^a-zA-Z0-9\s]/g;
	return text.replace(re, '');
}

export function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}