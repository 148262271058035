<template>

	<div :style="sizeStyle + colorStyleVar" class="loader-container">

		<svg :style="sizeStyle" xmlns="http://www.w3.org/2000/svg" width="17.174" height="17.2"
			 viewBox="0 0 17.174 17.2">
			<g :style="square1Pos" v-html="squareContent"></g>
			<g :style="square2Pos" v-html="squareContent"></g>
			<g :style="square3Pos" v-html="squareContent"></g>
		</svg>

	</div>

</template>

<script>

export default {
	name: "AppLoader",
	data() {
		return {
			leftX: -396.4,
			rightX: -386.203,
			upperY: -913.4,
			lowerY: -903.177,
			position: 0,
			changeInterval: null,
			squareContent: '<path fill="var(--loader-color)" transform="translate(397 914)" d="M.547-.6H5.231A1.147,1.147,0,0,1,6.377.546V5.231A1.147,1.147,0,0,1,5.231,6.377H.547A1.148,1.148,0,0,1-.6,5.23V.547A1.148,1.148,0,0,1,.547-.6ZM5.177.6H.6V5.177H5.177Z"/>\n' +
				'<path fill="var(--loader-color)" d="M.137-.6H1.308a.738.738,0,0,1,.737.737V1.308a.738.738,0,0,1-.737.737H.137A.738.738,0,0,1-.6,1.308V.137A.738.738,0,0,1,.137-.6Z" transform="translate(399.166 916.166)"/>'
		}
	},
	props: {
		color: {type: String, default: ''},
		size: {type: Number, default: 50},
	},
	computed: {
		sizeStyle() {
			return 'height: '+this.size+'px; width: '+this.size+'px;';
		},
		colorStyleVar() {
			return this.color ? '--loader-color: '+this.color : '--loader-color: var(--color-primary)';
		},

		topLeft() {
			return this.getPrefixedStyle(this.leftX, this.upperY);
		},
		topRight() {
			return this.getPrefixedStyle(this.rightX, this.upperY);
		},
		bottomLeft() {
			return this.getPrefixedStyle(this.leftX, this.lowerY);
		},
		bottomRight() {
			return this.getPrefixedStyle(this.rightX, this.lowerY);
		},

		square1Pos() {
			return this.position === 0 ? this.bottomRight : this.position === 1 ? this.bottomLeft : this.position === 2 ? this.topLeft : this.topRight
		},
		square2Pos() {
			return this.position === 0 ? this.topLeft : this.position === 1 ? this.topRight : this.position === 2 ? this.bottomRight : this.bottomLeft
		},
		square3Pos() {
			return this.position === 0 ? this.bottomLeft : this.position === 1 ? this.topLeft : this.position === 2 ? this.topRight : this.bottomRight
		},
	},
	methods: {
		getPrefixedStyle(X, Y) {
			return '-webkit-transform: translate('+X+'px, '+Y+'px); -ms-transform: translate('+X+'px, '+Y+'px); transform: translate('+X+'px, '+Y+'px)';
		}
	},
	mounted() {
		let self = this;
		this.changeInterval = setInterval(function () {
			self.position = (self.position + 1) % 4;
		}, 320);
	},
	beforeUnmount() {
		clearInterval(this.changeInterval);
	}

}
</script>

<style lang="scss" scoped>

:root {
	--loader-color: $primary;
}
.loader-container {
	@include centered;

	svg {
		g {
			-webkit-transition: -webkit-transform 0.3s ease;
			transition: -webkit-transform 0.3s ease;
			-o-transition: transform 0.3s ease;
			transition: transform 0.3s ease;
			transition: transform 0.3s ease, -webkit-transform 0.3s ease;
		}

		&:deep(path) {
			fill: var(--loader-color);
		}
	}
}

</style>