import { defineStore } from 'pinia';
import {useLanguageStore} from "@/shared/stores/language.store";

export const useValuesStore = defineStore('values', {
	state: () => ({
		forceCloseAllBottomSheets: false,
	}),
	getters: {
		paymentsMethods: () => {
			const languageStore = useLanguageStore();
			return {
				cash: {
					name: languageStore.getString('cash'),
					color: '#85bb65'
				},
				stripe: {
					name: languageStore.getString('cards'),
					color: '#5433FF'
				},
				pos: {
					name: languageStore.getString('direct_pos'),
					color: '#1da4f8'
				},
				satispay: {
					name: 'Satispay',
					color: '#f94c43'
				},
				manual: {
					name: languageStore.getString('manual'),
					color: '#000000'
				},
				klarna: {
					name: 'Klarna',
					color: '#ffc0cb'
				},
				btc: {
					name: 'Bitcoin',
					color: '#F7931A'
				},
				ticket: {
					name: languageStore.getString("ticket"),
					color: '#2d690a'
				},
				paylater: {
					name: languageStore.getString("paylater"),
					color: '#d01607'
				},
				check: {
					name: languageStore.getString("check"),
					color: '#b2960b'
				},
				wire: {
					name: languageStore.getString("wire"),
					color: '#0db49e'
				},
				multi: {
					name: languageStore.getString("multi_payment"),
					color: '#a30db4'
				},
				external: {
					name: languageStore.getString("external"),
					color: '#9a9393'
				},
				other: {
					name: 'Other',
					color: '#6c33cb'
				},
				unpaid: {
					name: languageStore.getString('not_paid'),
					color: '#ff0000'
				}
			};
		}
	},
});